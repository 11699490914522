import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { HomeService } from 'src/app/services/home.service';
import { SeoService } from 'src/app/services/seo.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: any;
  title: string = '';
  address: any;
  contactInfo: any;
  socialMedia: any;
  formFields: any = [];
  formSelect: any;
  button: any;
  formTouched = false;
  successFull = "";
  errorMessage = "";
  warningMessage = "";

  private seo = inject(SeoService);

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private translateService: TranslateService,
    private commonService: CommonService,
    private homeService : HomeService,
    private sharedService: SharedService
  ) {
    this.router.events.subscribe((event) => {
    if (event instanceof NavigationEnd) {
      window.scrollTo(0, 0);
    }
  });
}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      fullName: ['', Validators.required],
      email: ['', Validators.required],
      subject: [''],
      package: [''],
      message: ['']
    });

    this.commonService.getContact().subscribe((contact: any) => {
      const data = contact?.data?.attributes;

      this.title = data?.title;
      this.address = data?.address;
      this.contactInfo = data?.contactInfo;
      this.socialMedia = data?.socialMediaInfo;

      this.formFields = data?.formControlNames;
      this.formSelect = data?.selectForm[0];
      this.button = data?.submitButton;

      const seoData = data?.seo;
      this.seo.initializeSeo(seoData);
    });

    this.sharedService.selectedPackage$.subscribe(packageId => {
      if (packageId !== null) {
        this.setDropdownValue(packageId);
      }
    });
  }

  setDropdownValue(packageId: number): void {
    let dropdownValue = '';
    switch (packageId) {
      case 1:
        dropdownValue = 'Base';
        break;
      case 2:
        dropdownValue = 'Pro';
        break;
      case 3:
        dropdownValue = 'Premium';
        break;
      default:
        dropdownValue = '';
    }
    this.contactForm.patchValue({ package: dropdownValue });
  }

  submitForm(): void {
    this.formTouched = true;
    this.successFull = "";
    this.errorMessage = "";
    this.warningMessage = "";

    if (this.contactForm?.valid) {
      this.homeService.sendEmail(this.contactForm.value)
        .then((result: any) => {
          this.contactForm.reset();
          this.successFull = this.translateService.instant("message.success-message-sent");
          this.formTouched = false;
          setTimeout(() => {
            this.successFull = "";
          }, 5000);
        })
        .catch((err: any) => {
          this.errorMessage = `${this.translateService.instant("message.error-message-sent")}: ${err.error.error.message}`;
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        });
    } else {
      this.warningMessage = this.translateService.instant("message.warning-message-sent");
    }
  }

  goToSocial(url: any) {
    window.open(url, '_blank');
  }
}
