<div class="container-fluid mt-5 mb-n30 pt-5" *ngIf="title">
    <div class="row">
        <div class="col-10 offset-1 col-lg-8 offset-lg-2" [@downToUp]="tryOneMonthState ? 'initial' : 'default'">
            <div class="card border-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-lg-6 d-flex justify-content-around align-items-center">
                        <h2 class="font-weight-bold my-5">{{title}}</h2>
                    </div>
                    <div class="col-12 col-lg-6 d-flex justify-content-around align-items-center">
                        <button type="button" class="py-3 px-md-3 btn-try" (click)="goToTry(buttonTry?.buttonUrl)">{{buttonTry?.buttonText}}</button>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
