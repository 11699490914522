import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { downToUp } from '../../animations/keyframes';

@Component({
  selector: 'app-try-conome',
  templateUrl: './try-conome.component.html',
  styleUrls: ['./try-conome.component.scss'],
  animations: [downToUp]
})
export class TryConomeComponent implements OnInit {
  title: string = '';
  buttonTry: any;
  tryOneMonthState: boolean = false;

  constructor(private homeService: HomeService,
              private scrollService: ScrollService) {}

  ngOnInit(): void {
    this.homeService.getTryConome().subscribe((text: any) => {
      const data = text?.data?.attributes;
      this.title = data?.tryConome?.title;
      this.buttonTry = data?.tryConome?.tryButton;
    })

    this.scrollService.getScrollPosition().subscribe(position => {
      if(position > 7500) {
        this.tryOneMonthState = true;
      }
      if(position > 7567) {
        this.tryOneMonthState = false;
      }
    })
  }

  goToTry(url: any) {
    window.location.href = url;
  }
}
