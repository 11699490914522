<div class="container-fluid bg-white my-5 pt-5" *ngIf="title">
    <div class="row text-center mb-5">
        <div class="col">
            <h2>{{title}}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-8">
            <div class="row mt-5">
                <div class="col-md-4 offset-md-2 card-hover" *ngFor="let category of categories.slice(0, 2)" [@downToUp]="categoryState ? 'initial' : 'default'">
                    <span class="icon-bg-2">
                        <i *ngIf="!category?.photo" class="fa {{category.imgIcon}}" aria-hidden="true"></i>
                        <img *ngIf="category?.photo" class="icon-img-size" [src]="env + category?.photo" [alt]="category?.alt" [width]="240" [height]="160"/>
                    </span>
                    <h4 class="my-3">{{category?.name}}</h4>
                    <p class="mb-5">{{category?.description}}</p>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-4 offset-md-2 card-hover" *ngFor="let category of categories.slice(2, 4)" [@downToUp]="categoryStateTwo ? 'initial' : 'default'">
                    <span class="icon-bg-2">
                        <i *ngIf="!category?.photo" class="fa {{category.imgIcon}}" aria-hidden="true"></i>
                        <img *ngIf="category?.photo" class="icon-img-size" [src]="env + category?.photo" [alt]="category?.alt" [width]="240" [height]="160"/>
                    </span>
                    <h4 class="my-3">{{category?.name}}</h4>
                    <p class="mb-5">{{category?.description}}</p>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-4 offset-md-2 card-hover" *ngFor="let category of categories.slice(4, 6)" [@downToUp]="categoryStateThree ? 'initial' : 'default'">
                    <span class="icon-bg-2">
                        <i *ngIf="!category?.photo" class="fa {{category.imgIcon}}" aria-hidden="true"></i>
                        <img *ngIf="category?.photo" class="icon-img-size" [src]="env + category?.photo" [alt]="category?.alt" [width]="240" [height]="160"/>
                    </span>
                    <h4 class="my-3">{{category?.name}}</h4>
                    <p id="paketat" class="mb-5">{{category?.description}}</p>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-4 offset-md-2 card-hover" *ngFor="let category of categories.slice(6, 8)" [@downToUp]="categoryStateFour ? 'initial' : 'default'">
                    <span class="icon-bg-2">
                        <i *ngIf="!category?.photo" class="fa {{category.imgIcon}}" aria-hidden="true"></i>
                        <img *ngIf="category?.photo" class="icon-img-size" [src]="env + category?.photo" [alt]="category?.alt" [width]="240" [height]="160"/>
                    </span>
                    <h4 class="my-3">{{category?.name}}</h4>
                    <p id="paketat" class="mb-5">{{category?.description}}</p>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-4 offset-md-2 card-hover" *ngFor="let category of categories.slice(8, 10)" [@downToUp]="categoryStateFive ? 'initial' : 'default'">
                    <span class="icon-bg-2">
                        <i *ngIf="!category?.photo" class="fa {{category.imgIcon}}" aria-hidden="true"></i>
                        <img *ngIf="category?.photo" class="icon-img-size" [src]="env + category?.photo" [alt]="category?.alt" [width]="240" [height]="160"/>
                    </span>
                    <h4 class="my-3">{{category?.name}}</h4>
                    <p id="paketat" class="mb-5">{{category?.description}}</p>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-4 offset-md-2 card-hover" *ngFor="let category of categories.slice(10, 12)" [@downToUp]="categoryStateSix ? 'initial' : 'default'">
                    <span class="icon-bg-2">
                        <i *ngIf="!category?.photo" class="fa {{category.imgIcon}}" aria-hidden="true"></i>
                        <img *ngIf="category?.photo" class="icon-img-size" [src]="env + category?.photo" [alt]="category?.alt" [width]="240" [height]="160"/>
                    </span>
                    <h4 class="my-3">{{category?.name}}</h4>
                    <p id="paketat" class="mb-5">{{category?.description}}</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 card-hover show-hide">
            <img [src]="env + pcImg" [alt]="pcImgAlt" [width]="240" [height]="200" />
        </div>
    </div>
</div>
