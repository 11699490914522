import { Component, OnInit, HostListener, Inject, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { ScrollService } from 'src/app/services/scroll.service';
import { LANGUAGES } from './constants';
import { HomeService } from './services/home.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  favIcon: HTMLLinkElement | null = this.document.querySelector('#favicon');

  title = 'conome-website';
  env = environment.baseUrl;

  constructor(private translate: TranslateService,
              @Inject(DOCUMENT) private document: Document,
              private scrollService: ScrollService,
              private homeService: HomeService,
              private renderer: Renderer2,
              private titleService: Title) {
    translate.addLangs(LANGUAGES.map(lang => lang.languageShort));
    translate.setDefaultLang(LANGUAGES[1].languageShort);
    translate.use(localStorage.getItem("language") || LANGUAGES[1].languageShort);
    if (localStorage.getItem("language") === null)
      localStorage.setItem("language", LANGUAGES[1].languageShort);
  }

  ngOnInit(): void {
    this.homeService.getSeo().subscribe((entity: any) => {
      const data = entity?.data?.attributes;
      const favIconUrl = data?.favIcon?.data?.attributes?.url;
      const pageTitle = data?.pageTitle;
      const googleAnalyticsId = data?.seoGoogle?.googleAnalyticsId; 

      if (favIconUrl) {
        const fullFavIconUrl = `${this.env}${favIconUrl}`;
        const favIconElement = this.document.getElementById('favicon') as HTMLLinkElement;
        if (favIconElement) {
          this.renderer.setAttribute(favIconElement, 'href', fullFavIconUrl);
        }
      }

      this.titleService.setTitle(pageTitle);

      if (googleAnalyticsId) {
        this.loadGoogleAnalytics(googleAnalyticsId);
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    const scrollPosition = this.document.documentElement.scrollTop;
    this.scrollService.setScrollPosition(scrollPosition);
  }

  private loadGoogleAnalytics(googleAnalyticsId: string): void {
    const script = this.renderer.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    script.async = true;
    this.renderer.appendChild(this.document.head, script);

    const inlineScript = this.renderer.createElement('script');
    const scriptContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${googleAnalyticsId}', {
        'anonymize_ip': true,
        'cookie_flags': 'SameSite=None;Secure'
      });
    `;
    inlineScript.text = scriptContent;
    this.renderer.appendChild(this.document.head, inlineScript);
  }
}
