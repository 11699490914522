import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private selectedPackageSubject = new BehaviorSubject<number | null>(null);
  selectedPackage$ = this.selectedPackageSubject.asObservable();

  setSelectedPackage(packageId: number): void {
    this.selectedPackageSubject.next(packageId);
  }

  getSelectedPackage(): number | null {
    return this.selectedPackageSubject.value;
  }
}
