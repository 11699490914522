import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-user-experience',
  templateUrl: './user-experience.component.html',
  styleUrls: ['./user-experience.component.scss']
})
export class UserExperienceComponent implements OnInit {
  title:string = '';
  laptopImage: any;
  laptopImageAlt: string = '';
  phoneImage: any;
  phoneImageAlt: string = '';
  items:any = [];
  env = environment.baseUrl;

  constructor(private homeService: HomeService) {}

  ngOnInit(): void {
    this.homeService.getUserExperience().subscribe((entity: any) => {
      const data = entity?.data?.attributes;
      
      this.title = data?.title;
      this.laptopImage = this.env + data?.imageLaptop?.data?.attributes?.url;
      this.laptopImageAlt = data?.imageLaptop?.data?.attributes?.alternativeText;

      this.phoneImage = this.env + data?.imagePhone?.data[0]?.attributes?.url;
      this.phoneImageAlt = data?.imagePhone?.data[0]?.attributes?.alternativeText;

      this.items = data?.textImage.map((item: any) => {
        return {
          name: item?.name,
          description: item?.description,
          imgIcon: item?.iconImage?.icon,
          photo: item?.iconImage?.photo?.data?.attributes?.url,
          alt: item?.iconImage?.photo?.data?.attributes?.alternativeText
        }
      })
    })
  }
}
