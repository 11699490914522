import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/services/home.service';
import { leftToRight } from '../../animations/keyframes';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  animations: [leftToRight]
})
export class CompaniesComponent implements OnInit {
  title: string = '';
  conome: string = 'CONOME';
  logos:any = [];
  env = environment.baseUrl;
  companiesLogos = false;

  constructor(private scrollService: ScrollService,
              private homeService: HomeService) {}

  ngOnInit(): void {
    this.homeService.getCompanies().subscribe((company: any) => {
      const data = company?.data?.attributes;

      this.title = data?.title;
      this.logos = data?.companiesLogos[0]?.MultiImage?.data.map((logo:any)=> {
        return {
          logo: logo?.attributes?.url,
          name: logo?.attributes?.name,
          alt: logo?.attributes?.alternativeText,
          caption: logo?.attributes?.caption 
        }
      })
    })
    
    this.scrollService.getScrollPosition().subscribe((position) => {
      if(position > 7000) {
        this.companiesLogos = true;
      }
      if(position > 7067) {
        this.companiesLogos = false;
      }
    })
  }
}
