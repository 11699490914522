<div class="container my-5 pt-5" *ngIf="title">
    <div class="row text-center mb-5">
        <div class="col">
            <h2>{{title}} <br/><span class="text-uppercase font-weight-bold">{{conome}}</span></h2>
        </div>
    </div>
    <div class="row mobile-alignment" [@leftToRight]="companiesLogos ? 'initial' : 'default'">
        <div class="col m-4 text-center" *ngFor="let logo of logos">
            <div class="img-container">
                <img class="img-logo-format pointer" [src]="env + logo?.logo" [alt]="logo.alt" [width]="100" [height]="100"/>
            </div>
        </div>
    </div>
</div>
