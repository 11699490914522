<nav [ngClass]="scrolled ? 'bg-white' : 'bg-primary'" class="navbar fixed-top navbar-expand-lg navbar-light h-14">
  <div class="container-lg">
      <a class="navbar-brand" href="#" (click)="goHome()">
          <img class="logo" [class.logo-white]="!scrolled" width="80" [src]="logo" [alt]="logoAlt" [width]="156" [height]="42">
      </a>
      <button class="navbar-toggler collapsed bs-0" [ngClass]="scrolled ? 'search-blue' : 'search-white'"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav m-auto px-4">
              <li class="nav-item" *ngFor="let menuItem of menuItems; let i = index">
                  <a [ngClass]="[scrolled ? 'text-primary' : 'text-white', menuItem.selected ? 'selected' : '']" class="nav-link pointer f-15" (click)="goTo(menuItem?.menuLink)">{{menuItem?.menuItems}}</a>
              </li>
              <li class="nav-item">
                  <div ngbDropdown class="d-inline-block pointer" #drop="ngbDropdown" (mouseover)='over(drop)' (mouseout)='out(drop)'>
                      <a [ngClass]="scrolled ? 'text-primary' : 'text-white'" class="nav-link dropdown-toggle hide-drop f-15" id="dropdownBasic1" ngbDropdownToggle>
                          {{dropdownItem}}
                      </a>
                      <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic1" (mouseout)='out(drop)'>
                          <button ngbDropdownItem *ngFor="let moreMenuItem of moreMenuItems" [routerLink]="moreMenuItem?.buttonUrl" class="px-4 py-2">{{moreMenuItem?.buttonName}}</button>
                      </div>
                  </div>
              </li>  
          </ul>
          <div class="languages-container">
              <label *ngFor="let language of languages" class="mr-15-white pointer menu-item"
                  [ngClass]="{'selected': languageSelected === language, 'text-primary': scrolled}" (click)="updateLanguage(language)">{{language | uppercase}}</label>
          </div>
          <div class="px-4">
              <button type="button" *ngFor="let button of buttons" [ngClass]="scrolled ? 'btn-menu-scroll' : 'btn-menu'" (click)="goToRegister(button?.buttonUrl)">{{button?.buttonText}}</button>
          </div>
      </div>
  </div>
</nav>
