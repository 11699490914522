import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { SeoService } from 'src/app/services/seo.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title: string = '';
  packagesTitle: string = '';
  packages: any = [];
  packageNames: any = [];
  packageButtons: any = [];

  private seo = inject(SeoService);

  constructor(
    private router: Router,
    private homeService: HomeService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.router.navigate(['/']);

    this.homeService.getPackages().subscribe((pack: any) => {
      const data = pack?.data?.attributes;

      this.title = data?.title;
      this.packagesTitle = data?.packagesTitle;
      this.packageNames = data.packageName;
      this.packages = data?.packagesComponent;
      this.packageButtons = data?.packageButtons;
    });
    this.homeService.getSeo().subscribe((seo:any) => {
      const seoData = seo.data.attributes.seo;
      this.seo.initializeSeo(seoData)
    })
  }

  setSelectedPackage(packageId: number): void {
    this.sharedService.setSelectedPackage(packageId);
    this.router.navigate(['/kontakt']);
  }
}
