import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/services/home.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { downToUp } from '../../animations/keyframes';

@Component({
  selector: 'app-usage-security',
  templateUrl: './usage-security.component.html',
  styleUrls: ['./usage-security.component.scss'],
  animations: [downToUp]
})
export class UsageSecurityComponent implements OnInit {
  env = environment.baseUrl;
  title: string = '';
  items: any = [];
  isInitial: boolean =  true;
  hoveredUsage: string = '';
  designState = false;

  constructor(private homeService: HomeService,
              private scrollService: ScrollService,
              private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.homeService.getUsageSecurity().subscribe((entity: any) => {
      const data = entity?.data?.attributes;
      
      this.title = data?.title;
      this.items = data?.UsageSecurity.map((item: any) => {
        return {
          title: item?.title,
          description: item?.description,
          image: item?.image?.data?.attributes?.url,
          alt: item?.image?.data?.attributes?.alternativeText,
          name: item?.image?.data?.attributes?.name,
          caption: item?.image?.data?.attributes?.caption
        }
      })
    })

    this.scrollService.getScrollPosition().subscribe((position) => {
      if(position > 2500) {
        this.designState = true;
      }
      if(position > 2567) {
        this.designState = false;
      }
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.toggle();
      this.cdr.detectChanges();
    }, 2000); 
  }

  toggle(){
    this.isInitial = !this.isInitial;
  }
}
