<div class="container my-5 pt-5" *ngIf="faqConome">
  <div class="row mb-5">
    <div class="col-12 col-lg-3 offset-lg-1">
      <div class="card border-0">
        <div class="card-body d-flex flex-column justify-content-around">
          <h5 class="font-weight-bold p-3">{{frequentQuestion}}</h5>
          <a class="p-3 a-weight-underline pointer" href="/faq-conome">{{faqConome}}</a>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-8">
      <h4 class="mb-4 mt-4 mt-lg-0 ml-2">{{faqConome}}</h4>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item" *ngFor="let faq of faqs; let i = index">
          <h2 class="accordion-header" id="heading{{i}}">
            <button class="accordion-button {{i != 0 ? 'collapsed' : ''}}" data-bs-toggle="collapse" 
                    [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
              {{faq.question}}
            </button>
          </h2>
          <div id="collapse{{i}}" class="accordion-collapse collapse {{i == 0 ? 'show' : ''}}" 
               [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div *ngFor="let answer of faq.answer.split('\n')">
                {{answer}}
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</div>
