<div class="container my-5 pt-5" *ngIf="title">
    <div class="row text-center mb-5">
        <div class="col">
            <h2 class="f-30" markdown [data]="title"></h2>
        </div>
    </div>
    <div class="row">
        <div class="col-1 mt-7">
            <img class="phone-img" [width]="116" [height]="259" [src]="phoneImage" [alt]="phoneImageAlt"/>
        </div>
        <div class="col-11 col-lg-5 col-xl-3 mt-7">
            <img class="laptop-img" [width]="800" [height]="478" [src]="laptopImage" [alt]="laptopImageAlt"/>
        </div>
        <div class="col-12 col-lg-4 offset-lg-2 col-xl-4 offset-xl-4">
            <div class="row mt-4 ml-2" *ngFor="let item of items; let i = index">
                <div class="col-12">
                    <span class="icon-bg-1 {{i === 0 ? 'bg-primary' : i === 1 ? 'bg-warning' : i === 3 ? 'bg-danger' : ''}}">
                        <img *ngIf="item?.photo" class="icon-img-size" [src]="env + item?.photo" [alt]="item?.alt" [width]="100" [height]="100"/>
                        <i *ngIf="!item?.photo" class="{{item?.imgIcon}}"></i>
                    </span>
                </div>
                <div class="col-12 mt-2">
                    <h3 class="bold f-18">{{item?.name}}</h3>
                    <p class="f-15">{{item?.description}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
