// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { isDevMode } from "@angular/core";

export const environment = {
    production: false,
    baseUrl: "https://cms.web.conome.mk",
    api_token: '3c942543952a874ef6bab023c0f119b8aa0926899f2145058ab5dca59365a1b019a90b6747c1601b12e10906176f0705f5084175fb7d2fa8449cf8673f887c5d47fa97c568ecd79ec20e3ba9eca66965edc3ae16a5098d46f4bf574975cd9f8b201051528bdf588adb738feba6ea404b0f3dea34038fa008beb8420d336e5a72'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
