import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IUserInfo } from '../interfaces';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  getReady() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/conome-ready?populate=deep&locale=${locale}`);
  }

  getSeo(){
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/seo?populate=deep&locale=${locale}`);
  }
  
  getModules() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/module?populate=deep&locale=${locale}`);
  }

  getFaq() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/faq?populate=deep&locale=${locale}`);
  }

  getCategories() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/category?populate=deep&locale=${locale}`);
  }

  getCompanies() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/company-logo?populate=deep&locale=${locale}`);
  }

  getTryConome() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/try-conome?populate=deep&locale=${locale}`);
  }

  getUsageSecurity() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/usage-security?populate=deep&locale=${locale}`);
  }
  
  getUserExperience() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/user-experience?populate=deep&locale=${locale}`);
  }

  getTutorials() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/tutorials-youtube?populate=deep&locale=${locale}`);
  }

  getPackages() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/package?populate=deep&locale=${locale}`);
  }

  async sendEmail(userInfo: IUserInfo) {
    return firstValueFrom(this.http.post(`${environment.baseUrl}/api/send-mail`, {
      to: "mail.conome@gmail.com",
      subject: "Contact",
      html: `
      <div>
      Name: ${userInfo.fullName}<br/>
      Email: ${userInfo.email}<br/>
      Subject: ${userInfo.subject}<br/>
      Package: ${userInfo.package}<br/>
      Message: ${userInfo.message}<br/>
      </div>
      `
    }));
  }
}
