import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit {
  searchValue: string = '';

  constructor(private modalService: NgbModal,
              private commonService: CommonService) {}

  ngOnInit(): void {
      this.commonService.getSearchContent().subscribe(text => {
      })
  }            

  closeModal() {
    this.modalService.dismissAll();
  }
}
