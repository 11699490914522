import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, searchText: string): any {
    if(!searchText) return value;
    return value.filter((item: any) => item?.toLowerCase().includes(searchText?.toLowerCase()));
  }

}
