<div class="footer-container">
    <div class="container">
      <div class="row pb-5">
        <div class="col-12 col-sm-6 col-lg-3 mt-5 pt-5">
          <img class="mb-1" [src]="appLogo" [alt]="appLogoAlt" [width]="156" [height]="42">
          <p class="my-4"><span>&#169;</span> {{year}} - {{copyRight}} <span class="blue-2 pointer" (click)="goToiSolve(gotoiSolve?.buttonUrl)">{{gotoiSolve?.buttonText}}</span></p>
          <img class="isolve-img pointer" [src]="iSolveLogo" [alt]="iSolveLogoAlt" [width]="492" [height]="160" (click)="goToiSolve(gotoiSolve?.buttonUrl)">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 mt-5">
          <h5 class="mb-5">{{footerContactTitle}}</h5>
          <p *ngFor="let contact of footerContact">{{contact?.name}} <a href="{{contact?.to}}">{{contact?.info}}</a></p>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 mt-5">
          <h5 class="mb-5">{{footerHelpTitle}}</h5>
          <p *ngFor="let help of footerHelp; index as i" class="pointer" [routerLink]="help?.buttonUrl" (click)="help?.buttonText === 'Support' || 'Подршка' ? scrollToFAQ() : null">{{help?.buttonText}}
            <span *ngIf="i===0" class="blue-3 pointer">{{ 'tutorial.new' | translate }}</span>
          </p>
        </div>        
        <div class="col-12 col-sm-6 col-lg-3 mt-5">
          <h5 class="mb-5">{{footerFollowTitle}}</h5>
          <div class="text-white">
            <span *ngFor="let follow of footerFollow" class="social-media-bg pointer" (click)="goToSocial(follow?.url)">
              <i class="bi bi-{{follow?.icon}}"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <img class="swiss-badge lazyload" src="\assets\icons\swiss-badge.png" data-src="{{swissLogo}}" alt="{{swissLogoAlt}}" [width]="120" [height]="120">
  </div>
  