<div class="container-fluid overflow-hidden bg-primary h-85 mb-5">
  <div class="row align-items-center">
      <div class="col-12 col-lg-6 text-white p-5">
          <h1 class="font-800 display-1">{{ error }}</h1>
          <h2>{{title}}</h2>
          <p class="my-4">{{ description }}</p>
          <div class="btn-container my-4">
              <div class="row">
                  <div class="col-12 col-md-6 d-flex justify-content-start btn-container">
                      <button type="button" class="btn-white mb-3" (click)="goTo(redirectTo1)">{{ buttonText1 }}<i class="bi bi-arrow-right-short"></i></button>
                      <button type="button" class="btn-blue mb-3" (click)="goTo(redirectTo2)">{{ buttonText2 }}<i class="bi bi-arrow-right-short"></i></button>
                  </div>
              </div>                 
          </div>
      </div>
      <!-- <div class="col-12 col-lg-6 p-5">
          <img [src]="env + image" [alt]="imageAlt" class="home-img"/>
      </div> -->
  </div>
</div>
