<div class="container-fluid overflow-hidden bg-primary h-85 d-flex align-items-center">
  <!-- <div class="line-container">
    <img *ngIf="line1" [src]="env + line1" class="line line-1" alt="line 1" [@leftToRight]="isInitial ? 'initial' : 'default'"/>
    <img *ngIf="line2" [src]="env + line2" class="line line-2" alt="line 2" [@leftToRight]="isInitial ? 'initial' : 'default'"/>
    <img *ngIf="line3" [src]="env + line3" class="line line-3" alt="line 3" [@leftToRight]="isInitial ? 'initial' : 'default'"/>
  </div> -->
  
    <div class="row w-100">
      <div class="col-12 col-lg-5 offset-lg-1 d-flex justify-content-center">
         <img *ngIf="image" [@leftToRight]="isInitial ? 'initial' : 'default'" class="home-img" [src]="env + image" [width]="750" [height]="500" [alt]="imageAlt"/>
      </div>
      <div class="col-12 col-lg-5 text-white d-flex flex-column justify-content-center w-32 text-center-mobile" [@rightToLeft]="isInitial ? 'initial' : 'default'">
        <h1 class="f-50">{{title}} <br><span class="text-uppercase font-800">{{conome}}</span>?</h1>
        <p markdown class="my-4 f-15 f-400" [data]="description"></p>
        <div class="btn-container my-4">
          <div class="row">
            <div class="col-12 col-md-6 d-flex justify-content-start justify-content-center-mobile btn-container">
              <button *ngFor="let button of buttons; let i = index" type="button" class="{{i === 0 ? 'btn-white' : 'btn-blue'}} mb-3 mx-2" (click)="goTo(button?.buttonUrl)">
                {{button?.buttonText}}<i class="bi bi-arrow-right-short"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>