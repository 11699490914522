import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private scrollPosition = new Subject<number>();

  constructor() { }

  getScrollPosition() {
    return this.scrollPosition.asObservable();
  }

  setScrollPosition(position: number) {
    this.scrollPosition.next(position);
  }
}
