import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient, private meta: Meta) { }

  getMenu() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/menu?populate=deep&locale=${locale}`);
  }

  getFooter() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/footer?populate=deep&locale=${locale}`);
  }

  getContact() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/contact?populate=deep&locale=${locale}`);
  }

  getTutorialVideos() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/tutorial?populate=deep&locale=${locale}`);
  }

  getSearchContent() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/footer?populate=*&locale=${locale}`);
  }

  getErrorPage(){
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/error-page?populate=deep&locale=${locale}`);
  }
}
