<div class="overflow-hidden">
    <div class="d-flex justify-content-center align-items-center h-14 mt-100 bg-primary">
      <h1 class="text-white custom-contact">{{title}}</h1>
    </div>
    <div class="row mt-5">
      <div class="col-10 offset-1 col-md-4 offset-md-2 col-lg-3 mt-5">
        <h5 class="mb-4">{{address?.Address}}</h5>
        <p class="col-grey" *ngFor="let addressInfo of address?.AddressInfo">{{addressInfo.AddressInfo}}</p>
        <hr />
        <h5 class="my-4">{{contactInfo?.title}}</h5>
        <p class="col-grey" *ngFor="let contact of contactInfo?.MailMob">{{contact?.name}} <a href="{{contact?.to}}">{{contact?.info}}</a></p>
        <hr />
        <h5 class="my-4">{{socialMedia?.title}}</h5>
        <div class="my-4 text-white">
          <span *ngFor="let media of socialMedia?.iconImage" class="social-media-bg pointer" (click)="goToSocial(media?.url)">
            <i class="bi bi-{{media?.icon}}"></i>
          </span>
        </div>
      </div>
      <div class="col-10 offset-1 col-md-4 col-lg-3 mt-5">
        <form [formGroup]="contactForm" class="form-group" (ngSubmit)="submitForm()">
          <div class="my-4" *ngFor="let field of formFields?.slice(0, 3); let i = index">
            <label for="exampleFormControlInput{{i + 1}}" class="form-label col-grey">{{field?.labelName}}</label>
            <input type="text" formControlName="{{field?.formControlName}}" class="form-control" id="exampleFormControlInput{{i + 1}}">
            <div *ngIf="i == 0 ? (!contactForm.get('fullName').value && formTouched) : 
                        i == 1 ? (!contactForm.get('email').value && formTouched) :''" class="text-danger">
              {{field?.formControlError}}
            </div>
          </div>
          <div class="mb-4">
            <label for="exampleFormControlInput4" class="form-label col-grey">{{formSelect?.formControlName}}</label>
            <select class="form-select" formControlName="package" [attr.aria-label]="formSelect?.formControlName">
              <option value="" selected disabled>-Please choose an option-</option>
              <option *ngFor="let selectOption of formSelect?.selectOptions" [value]="selectOption?.option">
                {{selectOption?.option}}
              </option>
            </select>
          </div>
          <div class="mb-4">
            <label for="exampleFormControlTextarea1" class="form-label col-grey">{{formFields[3]?.labelName}}</label>
            <textarea class="form-control" formControlName="{{formFields[3]?.formControlName}}message" id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
          <div class="mb-4">
            <button type="submit" class="btn btn-outline-primary px-5">{{button}}</button>
          </div>
        </form>
        <label class="text-success">{{successFull}}</label>
        <label class="text-danger">{{errorMessage}}</label>
        <label *ngIf="!contactForm.get('fullName').dirty || !contactForm.get('email').dirty" class="text-warning">{{warningMessage}}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-10 offset-1 col-md-8 offset-md-2 my-5">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1397.1786439513414!2d20.96190872153575!3d41.98627369738527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2smk!4v1587980062739!5m2!1sen!2smk"
          class="border-0" width="100%" height="450" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0">
        </iframe>
      </div>
    </div>
  </div>
  