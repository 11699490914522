import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TutorialsComponent } from './pages/tutorials/tutorials.component';
import { ErrorPageComponent } from './components/errorPage/errorPage.component';

const routes: Routes = [
  {path:"", component: HomeComponent},
  {path:"kontakt", component: ContactComponent},
  {path:"tutoriale", component: TutorialsComponent},
  { path: "**", component: ErrorPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
