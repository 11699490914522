import { Component, inject, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})
export class TutorialsComponent implements OnInit {
  title: string = '';
  videos: any;

  private seo = inject(SeoService);
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.commonService.getTutorialVideos().subscribe((video: any) => {
      this.title = video?.data?.attributes?.title;
      this.videos = video?.data?.attributes?.youtubeVideo.map((vid: any) => {
        return {
          ...vid,
          videoUrl: this.transformUrl(vid.videoUrl)
        };
      });
      const seoData = video?.data?.attributes?.seo;
      this.seo.initializeSeo(seoData);
    });
  }

  private transformUrl(url: string): string {
    const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|youtu\.be\/([^?&]+)/);
    const videoId = videoIdMatch ? videoIdMatch[1] || videoIdMatch[2] : null;
    return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
  }
}
