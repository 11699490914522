<div class="container-fluid my-5 pt-5 pr-pl-0" *ngIf="title">
    <div class="row youtube-tutorials h-35" [ngStyle]="{'background-image': 'url('+(env + backgroundImage || '')+')'}">
        <div class="col-12 col-md-6 offset-md-1 text-white d-flex flex-column justify-content-center">
            <h2 class="mt-42 ml-2">{{title}}</h2>
            <h6 class="text-align fw-300">{{description}}</h6>
            <button type="button" class="btn-watch" [routerLink]="button?.buttonUrl">{{button?.buttonText}}<i class="bi bi-play-fill"></i></button>
            <img class="yt-img" [width]="750" [height]="833" [src]="env + image" [alt]="imageAlt"/>
        </div>
    </div>
</div>
