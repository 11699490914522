import { Component, HostListener, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeService } from 'src/app/services/home.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { SearchModalComponent } from '../search-modal/search-modal.component';
import { environment } from 'src/environments/environment';
import { LANGUAGES } from 'src/app/constants';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  scrolled = false;
  menuItems: any;
  dropdownItem: any;
  moreMenuItems: any;
  buttons: any;
  logo: any;
  logoAlt: string = '';
  env: any;

  constructor(private modalService: NgbModal,
              private scroller: ViewportScroller,
              private commonService: CommonService,
              private scrollService: ScrollService) {}

  ngOnInit(): void {
    this.getMenu();

     this.scrollService.getScrollPosition().subscribe((position: any) => {
      let element = document.querySelector('.navbar') as HTMLElement;
      if(position > 30) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    })
  }
  
  get languages() {
    return LANGUAGES.map(language => language.languageShort);
  }

  get languageSelected() {
    return localStorage.getItem("language") || LANGUAGES[1].languageShort;
  }

  goHome() {
    location.reload();
  }

  updateLanguage(newLanguage: string) {
    localStorage.setItem("language", newLanguage);
    location.reload();
  }

  getMenu() {
    this.env = environment.baseUrl;
    this.commonService.getMenu().subscribe((menu: any) => {
      const data = menu?.data?.attributes;
      
      this.logo = this.env + data.appLogo?.data?.attributes?.url;
      this.logoAlt = data.appLogo?.data?.attributes?.alternativeText;
      this.menuItems = data?.menuItems;
      this.dropdownItem = data.moreMenuItems?.dropdown;
      this.moreMenuItems = data?.moreMenuItems?.dropdownItems
      this.buttons = data?.menuButtons;
    })
  }

  over(drop:NgbDropdown){
    drop.open();
  }
  out(drop:NgbDropdown){
    drop.close();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  goToRegister(url: any) {
    window.location.href = url;
  }

  openBackDropCustomClass() {
		this.modalService.open(SearchModalComponent, { fullscreen: true });
	}

  goTo(element: any) {
    window.location.href = '#';
    this.scroller.scrollToAnchor(element);
  }
}