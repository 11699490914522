<div class="container my-5 pt-5 text-center" *ngIf="title">
    <div class="row">
        <div class="col">
            <h2>{{title}} <br/>{{conome}}</h2>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-6 col-md-4" *ngFor="let module of modules; let i = index">
            <div class="card mt-3 p-4 custom-card pointer border-module" 
                 [class.border-multi-color]="selectedModule == module?.selectedModule" 
                 [class.border-transparent]="selectedModule != module?.selectedModule" 
                 [ngStyle]="{'--border-color': module.border}">
                <div class="card-body text-center" (click)="chooseModule(module?.selectedModule)">
                    <span class="icon-bg d-flex align-items-center justify-content-center {{i == 1 ? 'bg-primary' : i == 2 ? 'bg-warning' : i == 3 ? 'bg-purple' : i == 4 ? 'bg-danger' : ''}}">
                        <img *ngIf="module?.photo" class="icon-img-size" [src]="env + module?.photo" [alt]="module?.photoAlt" [width]="100" [height]="100"/>
                        <i *ngIf="!module?.photo" class="bi bi-{{module?.icon}}"></i>
                    </span>
                    <h1 class="mt-2 f-16">{{module?.name}}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5 pt-5">
        <div class="col p-3" [ngStyle]="{'background-image': 'url('+(env + backgroundImage || '')+')'}">
            <div *ngFor="let module of modules">
                <img *ngIf="selectedModule == module?.selectedModule" [width]="1680" [height]="936" class="home-img" [src]="env + module?.screenshot" [alt]="module?.screenshotAlt"/>
                <img *ngIf="selectedModule == module?.selectedModule" [width]="272" [height]="554" class="home-mobile" [src]="env + module?.phoneImg" [alt]="module?.phoneImgAlt"/>
            </div>
        </div>
    </div>
</div>
