import { trigger, state, style, animate, transition, query, stagger } from '@angular/animations';

export const leftToRight = trigger('leftToRight', [
  state('initial', style({
    opacity: 0,
    transform: 'translateX(-100%)'
  })),
  state('default', style({
    opacity: 1,
    transform: 'translateX(0)'
  })),
  transition('initial => default', [
    animate('1s ease-in-out')
  ])
]);

export const leftToRightSm = 
  trigger('leftToRightSm', [
    state('initialSm', style({
      opacity: 0,
      transform: 'translateX(-100%) scale(0.8)'
    })),
    state('defaultSm', style({
      opacity: 1,
      transform: 'translateX(0) scale(1)'
    })),
    transition('initialSm => defaultSm', [
      animate('0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)')
    ])
  ]);

export const rightToLeft = trigger('rightToLeft', [
  state('initial', style({
    opacity: 0,
    transform: 'translateX(100%)'
  })),
  state('default', style({
    opacity: 1,
    transform: 'translateX(0)'
  })),
  transition('initial => default', [
    animate('1s ease-in-out')
  ])
]);

export const downToUp = trigger('downToUp', [
  state('initial', style({
    opacity: 0,
    transform: 'translateY(50%)'
  })),
  state('default', style({
    opacity: 1,
    transform: 'translateY(0)'
  })),
  transition('initial => default', [
    animate('1s ease-in-out')
  ]),
  transition('default => initial', [
    animate('0.5s ease-in-out')
  ])
]);

export const rightToLeftSlow = trigger('rightToLeftSlow', [
  state('initial', style({
    opacity: 0,
    transform: 'translateX(100%) scale(1.2)'
  })),
  state('default', style({
    opacity: 1,
    transform: 'translateX(0) scale(1)'
  })),
  transition('initial => default', [
    animate('0.7s ease-in-out')
  ])
]);

export const zoomInRotate = trigger('zoomInRotate', [
  state('initial', style({
    opacity: 0,
    transform: 'scale(0.5) rotate(-15deg)'
  })),
  state('default', style({
    opacity: 1,
    transform: 'scale(1) rotate(0)'
  })),
  transition('initial => default', [
    animate('0.6s ease-in-out')
  ])
]);

export const staggeredLeftToRight = trigger('staggeredLeftToRight', [
  transition('* => *', [
    query(':enter', [
      style({ opacity: 0, transform: 'translateX(-100%)' }),
      stagger('100ms', [
        animate('0.5s ease-in-out', style({ opacity: 1, transform: 'translateX(0)' }))
      ])
    ], { optional: true })
  ])
]);
