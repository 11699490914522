<div class="overflow-hidden text-center">
    <div class="d-flex justify-content-center align-items-center h-14 mt-100 bg-primary">
      <h1 class="text-white custom-contact">{{title}}</h1>
    </div>
    <div class="row my-5 mx-2">
      <div class="mb-4 col-12 col-lg-6" *ngFor="let video of videos">
        <iframe class="video-wh" [src]="video?.videoUrl | safeUrl" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </div>
  