<div class="w-100 bg-blue-light" *ngIf="title">
        <div class="container my-5 pt-5" [@downToUp]="designState ? 'initial' : 'default'">
            <div class="row text-center mb-5">
                <div class="col">
                    <h2>{{title}}</h2>
                </div>
            </div>
            <div class="row show-hide">
                <div class="col-10 offset-1 d-flex justify-content-center pointer">
                    <div class="d-flex justify-content-center" (mouseenter)="hoveredUsage = 'Card1'" (mouseleave)="hoveredUsage = ''"
                        [ngClass]="hoveredUsage === 'Card1' ? 'nr-round-selected' : 'nr-round'"><span>1</span>
                    </div>
                    <hr [class.hr-2] = "hoveredUsage === 'Card2'" (mouseenter)="hoveredUsage = 'Card2'" (mouseleave)="hoveredUsage = ''"/>
                    <div class="d-flex justify-content-center" (mouseenter)="hoveredUsage = 'Card2'" (mouseleave)="hoveredUsage = ''"
                        [ngClass]="hoveredUsage === 'Card2' ? 'nr-round-selected' : 'nr-round'" ><span>2</span></div>
                    <hr [class.hr-3] = "hoveredUsage === 'Card3'" (mouseenter)="hoveredUsage = 'Card3'" (mouseleave)="hoveredUsage = ''"/>
                    <div class="d-flex justify-content-center" (mouseenter)="hoveredUsage = 'Card3'" (mouseleave)="hoveredUsage = ''"
                        [ngClass]="hoveredUsage === 'Card3' ? 'nr-round-selected' : 'nr-round'"><span>3</span></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4" *ngFor="let item of items; let i = index;">
                    <div [ngClass]="hoveredUsage === 'Card' + (i+1) ? 'bg-white' : 'bg-transparent'" 
                        class="card text-center my-4 p-4 border-0 pointer">
                        <div class="card-body" (mouseenter)="hoveredUsage = 'Card' + (i+1)" (mouseleave)="hoveredUsage = ''">
                            <img [width]="84" [height]="99" [src]="env + item?.image" [alt]="item?.alt"/>
                            <h5 class="my-4">{{item.title}}</h5>
                            <p id="kategorite">{{item?.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>