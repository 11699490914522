import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { RequestsInterceptor } from './requests.interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MarkdownModule, MarkedOptions, MarkdownModuleConfig, MarkedRenderer } from 'ngx-markdown';
import { ContactComponent } from './pages/contact/contact.component';
import { NgbModule, NgbDropdownModule, NgbTooltipModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchModalComponent } from './components/search-modal/search-modal.component';
import { ModuleComponent } from './components/module/module.component';
import { FaqComponent } from './components/faq/faq.component';
import { CategoryComponent } from './components/category/category.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { TryConomeComponent } from './components/try-conome/try-conome.component';
import { UsageSecurityComponent } from './components/usage-security/usage-security.component';
import { UserExperienceComponent } from './components/user-experience/user-experience.component';
import { ConomeTutorialComponent } from './components/conome-tutorial/conome-tutorial.component';
import { ConomeReadyComponent } from './components/conome-ready/conome-ready.component';
import { SearchPipe } from './pipes/search.pipe';
import { TutorialsComponent } from './pages/tutorials/tutorials.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

export function HttpLoaderFactory(http:HttpClient){
  return new TranslateHttpLoader(http);
}

const options: MarkdownModuleConfig = {
  markedOptions: {
      provide: MarkedOptions,
      useValue: {
          gfm: true,
          breaks: false,
          pedantic: false,
          smartLists: true,
          smartypants: false,
      },
      useFactory: markedOptionsFactory
  }
};

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  const linkRenderer = renderer.link;
  renderer.link = (href, title, text) => {
      const html = linkRenderer.call(renderer, href, title, text);
      return html.replace(/^<a /, '<a role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer" ');
  };

  return {
      renderer,
      gfm: true,
      breaks: false,
      pedantic: false,
      smartLists: true,
      smartypants: false,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    FooterComponent,
    ContactComponent,
    SearchModalComponent,
    ModuleComponent,
    FaqComponent,
    CategoryComponent,
    CompaniesComponent,
    TryConomeComponent,
    UsageSecurityComponent,
    UserExperienceComponent,
    ConomeTutorialComponent,
    ConomeReadyComponent,
    SearchPipe,
    TutorialsComponent,
    SafeUrlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MarkdownModule.forRoot(options),
    NgbModule,
    NgbModalModule,
    NgbDropdownModule,
    NgbTooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
