import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-errorPage',
  templateUrl: './errorPage.component.html',
  styleUrls: ['./errorPage.component.scss']
})
export class ErrorPageComponent implements OnInit {
  error:string = '';
  title: string = '';
  description: string = '';
  image: string = '';
  imageAlt: string = '';
  env = environment.baseUrl;
  buttonText1 :string = '';
  buttonText2 :string = '';
  redirectTo1 :string = '';
  redirectTo2 :string = '';


  constructor(private router: Router, private common: CommonService) { }

  ngOnInit() {
    this.common.getErrorPage().subscribe((error: any) => {
      const data = error?.data?.attributes;
      this.error = data?.error;
      this.title = data?.title;
      this.description = data?.description;
      this.buttonText1 = data?.buttonText1;
      this.redirectTo1 = data?.redirectTo1;
      this.buttonText2 = data?.buttonText2;
      this.redirectTo2 = data?.redirectTo2;
      const imgData = data?.img?.data[0]?.attributes;
      this.image = imgData?.url;
      this.imageAlt = imgData?.alternativeText || '404 Image';
    });
  }

  goTo(url: any) {
    window.location.href = url;
  }
}
