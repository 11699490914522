import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { HomeService } from 'src/app/services/home.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import 'lazysizes';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  env: any;
  year: any;
  appLogo: any;
  appLogoAlt: string = '';
  iSolveLogo: any;
  iSolveLogoAlt: string = '';
  copyRight: any;
  gotoiSolve: any;
  swissLogo: any;
  swissLogoAlt: string = '';
  footerContactTitle: string = '';
  footerContact: any;
  footerHelpTitle: string = '';
  footerHelp: any;
  footerFollowTitle: string = '';
  footerFollow: any;
  tutorialsNewLabel: string = '';

  constructor(
    private homeService: HomeService, 
    private commonService: CommonService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.env = environment.baseUrl;
    this.year = new Date().getFullYear();

    this.commonService.getFooter().subscribe((footer: any) => {
      const data = footer?.data?.attributes;

      this.appLogo = this.env + data?.FooterOne?.appLogo?.data?.attributes?.url;
      this.appLogoAlt = data?.FooterOne?.appLogo?.data?.attributes?.alternativeText;
      
      this.iSolveLogo = this.env + data?.FooterOne?.iSolveLogo?.data?.attributes?.url;
      this.iSolveLogoAlt = data?.FooterOne?.iSolveLogo?.data?.attributes?.alternativeText;

      this.copyRight = data?.FooterOne?.copyRight;
      this.gotoiSolve = data?.FooterOne?.goTo;

      this.swissLogo = this.env + data?.swissLogo?.data?.attributes?.url;
      this.swissLogoAlt = data?.swissLogo?.data?.attributes?.alternativeText;

      this.footerContactTitle = data?.FooterContact?.title;
      this.footerContact =  data?.FooterContact?.MailMob;

      this.footerHelpTitle = data?.FooterHelp?.title;
      this.footerHelp =  data?.FooterHelp?.helpInfo;

      this.footerFollowTitle = data?.FooterFollow?.title;
      this.footerFollow =  data?.FooterFollow?.iconImage;
    });
  }

  goToiSolve(url: string) {
    window.location.href = url;
  }

  goToSocial(url: string) {
    window.open(url, '_blank');
  }

  scrollToFAQ() {
    const element = document.getElementById('faqSection');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
