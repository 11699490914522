import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss']
})
export class ModuleComponent implements OnInit {
  title: string = '';
  conome: string = 'Conome';
  modules: any;
  env = environment.baseUrl;
  backgroundImage: any;
  selectedModule: string = 'pos';

  constructor(private homeService: HomeService) {}

  ngOnInit(): void {
    this.homeService.getModules().subscribe((module: any) => {
      const data = module?.data?.attributes;

      this.title = data?.title;
      this.backgroundImage = data?.imageBackground?.data?.attributes?.url;
      this.modules = data?.moduleNameIcon?.moduleImageIcon.map((module: any) => {
        return {
          icon: module?.icon,
          name: module?.name,
          selectedModule: module?.selectedModule,
          border: module?.border,
          screenshot: module?.moduleScreenShot?.data?.attributes?.url,
          screenshotAlt: module?.moduleScreenShot?.data?.attributes?.alternativeText,
          phoneImg: module?.modulePhoneImg?.data?.attributes?.url,
          phoneImgAlt: module?.modulePhoneImg?.data?.attributes?.alternativeText,
          photo: module?.photo?.data?.attributes?.url,
          photoAlt: module?.photo?.data?.attributes?.alternativeText
        }
      })
    })    
  }
  
  chooseModule(module: any) {
    this.selectedModule = module;
  }
}
