<div class="bg-light mt-100">
    <app-conome-ready></app-conome-ready>
    <div class="section-spacing">
        <app-module></app-module>
    </div>
    <div class="section-spacing">
        <app-conome-tutorial></app-conome-tutorial>
    </div>
    <div class="section-spacing">
        <app-user-experience></app-user-experience>
    </div>
    <div class="section-spacing">
        <app-usage-security></app-usage-security>
    </div>
    <div class="section-spacing">
        <app-category></app-category>
    </div>
    

    <!-- Package Small Container -->
    <div class="container my-5 px-5 pt-5 package-sm" *ngIf="title">
        <div class="row text-center mb-5">
            <div class="col">
                <h2 class="mb-5 f-600">{{title}}</h2>
                <h5 class="mt-5 mb-1">{{packagesTitle}}</h5>
            </div>
        </div>
        <div class="row" *ngFor="let paketa of packages">
            <div class="d-flex justify-content-center align-items-center tooltip-hover pointer" (click)="setSelectedPackage(paketa?.cmsSystems)">
                <i class="bi bi-plus-circle mb-2 mx-2"></i>
                <h6 class="text-center py-4 text-uppercase" placement="top" ngbTooltip="{{paketa?.cmsSystemsTooltip}}">{{paketa?.cmsSystems}}</h6>
            </div>
            
            <div class="row py-2 {{i % 2 != 1 ? 'bg-white' : ''}}" *ngFor="let package of packageNames;let i = index;">
                <div class="col">
                    <h6 class="text-start mt-1">{{package?.packageName}}</h6>
                </div>
                <div class="col text-center">
                    <i class="bi {{(i == 0 ? paketa?.base : i == 1 ? paketa?.pro : paketa?.premium) ? 'bi-check-circle check-icon text-end' : 'bi-x close-icon'}}"></i>
                </div>
            </div>
        </div>
    </div>

    <!-- Package Large Container -->
    <div class="container-xl my-5 px-4 pt-5 package-lg" *ngIf="title">
        <div class="row text-center mb-5">
            <div class="col">
                <h2>{{title}}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-3 g-0">
                <div class="card border-0 h-104-radius">
                    <div class="card-body text-center">
                        <h5 class="font-weight-bold my-4">{{packagesTitle}}</h5>
                    </div>
                </div>
            </div>
            <div class="col-3 g-0 text-center" *ngFor="let package of packageNames; let i = index;">
                <div class="card border-0 h-104-radius {{i === 0 ? 'bg-primary-lighter' : i === 1 ? 'bg-primary-light': 'bg-primary'}}" (click)="setSelectedPackage(package?.packageName)">
                    <div class="card-body">
                        <h5 class="text-white font-weight-bold my-2">{{package?.packageName}}</h5>
                        <h6 class="text-white font-weight-bold my-2">{{package?.packagePrice}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngFor="let paketa of packages">
            <div class="col-3 g-o">
                <div class="card bg-light border-0">
                    <div class="card-body d-flex flex-column">
                        <div class="d-inline-flex tooltip-hover pointer" (click)="setSelectedPackage(paketa?.cmsSystems)">
                            <i class="bi bi-info-circle info-icon"></i>
                            <h6 placement="top" ngbTooltip="{{paketa?.cmsSystemsTooltip}}">{{paketa?.cmsSystems}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3 g-0" *ngFor="let rows of [1, 2, 3]; let i = index;">
                <div class="card border-0 text-center {{i % 2 == 1 ? 'bg-light' : ''}}">
                    <div class="card-body d-flex flex-column">
                        <i class="bi {{(i == 0 ? paketa?.base : i == 1 ? paketa?.pro : paketa?.premium) ? 'bi-check-circle check-icon' : 'bi-x close-icon'}}"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-3 border-bottom"></div>
            <div id="serviset" class="col-3 py-5 border-bottom {{i % 2 != 1 ? 'bg-white' : ''}}" *ngFor="let packageButton of packageButtons; let i = index;">
              <button type="button" class="btn-menu" (click)="setSelectedPackage(i + 1)">{{packageButton?.buttonText}}</button>
            </div>
          </div>          
    </div>
    <div class="section-spacing">
    <app-faq id="faqSection"></app-faq>
</div>
<div class="section-spacing">
    <app-companies></app-companies>
</div>
    <div class="section-spacing">
    <app-try-conome></app-try-conome>
</div>
</div>
