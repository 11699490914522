import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqs:any = [];
  faqConome: string = '';
  frequentQuestion: string = '';
  env = environment.baseUrl;

  constructor(private homeService: HomeService) {}

  ngOnInit(): void {
    this.homeService.getFaq().subscribe((faqs:any)=> {
      const data = faqs?.data?.attributes;
      
      this.faqConome = data?.faqConome;
      this.frequentQuestion = data?.frequentQuestion;
      this.faqs = data?.Faq.map((faq:any)=> {
        return {
          id: faq?.id,
          question: faq?.question,
          answer: faq?.answer 
        }
      })
    })    
  }
}
