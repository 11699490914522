import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-conome-tutorial',
  templateUrl: './conome-tutorial.component.html',
  styleUrls: ['./conome-tutorial.component.scss']
})
export class ConomeTutorialComponent implements OnInit {
  env = environment.baseUrl;
  title: string = '';
  description: string = '';
  button: any;
  image: any;
  imageAlt: string = '';
  backgroundImage: any;


  constructor(private homeService: HomeService) {}

  ngOnInit(): void {
      this.homeService.getTutorials().subscribe((entity: any) => {
        const data = entity?.data?.attributes;
        
        this.title = data?.TutorialsYoutube?.title;
        this.description = data?.TutorialsYoutube?.description;
        this.button = data?.TutorialsYoutube?.tutorialButton;
        this.image = data?.TutorialsYoutube?.imageYoutube.data?.attributes?.url;
        this.imageAlt = data?.TutorialsYoutube?.imageYoutube.data?.attributes?.alternativeText;
        this.backgroundImage = data?.TutorialsYoutube.imageBackground.data?.attributes?.url;
      })
  }
}
