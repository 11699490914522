import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/services/home.service';
import { downToUp } from '../../animations/keyframes';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  animations: [downToUp]
})
export class CategoryComponent implements OnInit {
  title: string = "";
  pcImg: any;
  pcImgAlt: string = "";
  categories: any = [];
  env = environment.baseUrl;
  isInitial: boolean =  true;
  categoryState: boolean = false;
  categoryStateTwo: boolean = false;
  categoryStateThree: boolean = false;
  categoryStateFour: boolean = false;
  categoryStateFive: boolean = false;
  categoryStateSix: boolean = false;

  constructor(private scrollService: ScrollService,
              private homeService: HomeService,
              private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.homeService.getCategories().subscribe((category:any)=> {
      const data = category?.data?.attributes;

      this.title = data?.title;
      this.pcImg = data?.pcImg?.data?.attributes?.url;
      this.pcImgAlt = data?.pcImg?.data?.attributes?.alternativeText;
      this.categories = data?.category.map((category: any) => {
        return {
          name: category?.name,
          description: category?.description,
          imgIcon: category?.iconImage?.icon,
          photo: category?.iconImage?.photo?.data?.attributes?.url,
          alt: category?.iconImage?.photo?.data?.attributes?.alternativeText
        }
      })
    })

    this.scrollService.getScrollPosition().subscribe((position) => {
      if(position > 3200) {
        this.categoryState = true;
      }
      if(position > 3267) {
        this.categoryState = false;
      }

      if(position > 3600) {
        this.categoryStateTwo = true;
      }
      if(position > 3667) {
        this.categoryStateTwo = false;
      }

      if(position > 4000) {
        this.categoryStateThree = true;
      }
      if(position > 4067) {
        this.categoryStateThree = false;
      }

      if(position > 4400) {
        this.categoryStateFour = true;
      }
      if(position > 4467) {
        this.categoryStateFour = false;
      }

      if(position > 4800) {
        this.categoryStateFive = true;
      }
      if(position > 4867) {
        this.categoryStateFive = false;
      }

      if(position > 5200) {
        this.categoryStateSix = true;
      }
      if(position > 5267) {
        this.categoryStateSix = false;
      }
    })
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.toggle();
      this.cdr.detectChanges();
    }, 2000); 
  }


  toggle() {
    this.isInitial = !this.isInitial;
  }
}