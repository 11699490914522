import { AfterViewInit, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LANGUAGES } from 'src/app/constants';
import { HomeService } from 'src/app/services/home.service';
import { leftToRight, rightToLeft } from '../../animations/keyframes';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-conome-ready',
  templateUrl: './conome-ready.component.html',
  styleUrls: ['./conome-ready.component.scss'],
  animations: [leftToRight, rightToLeft]
})
export class ConomeReadyComponent implements OnInit, AfterViewInit {
  language: string = LANGUAGES[1].languageShort;
  env = environment.baseUrl;
  title: string = '';
  conome: string = 'CONOME';
  description: string = '';
  image: any;
  imageAlt: string = '';
  buttons: any;
  isInitial: boolean = true;
  line1: any;
  line2: any;
  line3: any;

  constructor(
    private homeService: HomeService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
   
    this.homeService.getReady().subscribe((entity: any) => {
      const data = entity?.data?.attributes;

      this.title = data?.title || '';
      this.description = data?.description || '';
      this.image = data?.image?.data?.attributes?.url || '';
      this.line1 = data?.line1?.data?.attributes?.url || '';
      this.line2 = data?.line2?.data?.attributes?.url || '';
      this.line3 = data?.line3?.data?.attributes?.url || '';
      this.imageAlt = data?.image?.data?.attributes?.alternativeText || '';
      this.buttons = data?.button.map((btn: any) => {
        return {
          buttonText: btn?.buttonText || '',
          buttonUrl: btn?.buttonUrl || ''
        };
      }) || [];
      
      this.isInitial = true;
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.toggle();
      this.cdr.detectChanges();
    }, 1000);
  }

  toggle() {
    this.isInitial = !this.isInitial;
  }

  goTo(url: any) {
    if (url) {
      window.location.href = url;
    } else {
      console.error('Invalid URL:', url);
    }
  }
}
